@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

/* In your global CSS file or component CSS module */
html {
  color-scheme: light;
}

body {
  margin: 0;
  font-family: "Arimo", sans-serif;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)),
    url(https://images.unsplash.com/photo-1513530534585-c7b1394c6d51?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)
      fixed center center;
}
